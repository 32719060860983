// import React, { createContext, useState, useContext } from 'react';

// const PriceFilterContext = createContext();

// export const PriceFilterProvider = ({ children }) => {
//   const [priceRange, setPriceRange] = useState([1, 9999]);
//   const [sortOrder, setSortOrder] = useState(null); // Устанавливаем значение по умолчанию в null

//   return (
//     <PriceFilterContext.Provider value={{ priceRange, setPriceRange, sortOrder, setSortOrder }}>
//       {children}
//     </PriceFilterContext.Provider>
//   );
// };

// export const usePriceFilter = () => useContext(PriceFilterContext);

import React, { createContext, useState, useContext, useEffect } from 'react';

const PriceFilterContext = createContext();

export const PriceFilterProvider = ({ children }) => {
  const [priceRange, setPriceRange] = useState([1, 49999]);
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedColors, setSelectedColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]); // Добавляем состояние для хранения всех доступных цветов

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await fetch('https://backend.balloonflower.kyiv.ua/api/colors');
        const data = await response.json();
        setAvailableColors(data);
      } catch (error) {
        console.error("Error fetching colors:", error);
      }
    };

    fetchColors();
  }, []);

  return (
    <PriceFilterContext.Provider value={{ priceRange, setPriceRange, sortOrder, setSortOrder, selectedColors, setSelectedColors, availableColors }}>
      {children}
    </PriceFilterContext.Provider>
  );
};

export const usePriceFilter = () => useContext(PriceFilterContext);
import * as React from 'react';
import { Box, Slider } from '@mui/material';
import './FilterSlider.css';
import { usePriceFilter } from '../../../../context/PriceFilterContext';

function FilterSlider() {
  const { priceRange, setPriceRange } = usePriceFilter();

  const handleChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <Box sx={{ width: 200 }} className="FilterSlider">
      <Slider
        aria-label="Default steps"
        value={priceRange}
        onChange={handleChange}
        min={1}
        max={49999}
        step={10}
        color="secondary"
      />
      <div>
        <p className="FilterText">
          Ціна: від: <span>{priceRange[0]}</span> грн до <span>{priceRange[1]}</span> грн
        </p>
      </div>
    </Box>
  );
}

export default FilterSlider;

import TitleSection from '../Title/TitleSection'
import './DeliveryOffer.css'
import DeliveryImg1 from '../../../Assets/Img/DeliveryOffer/delivery1.webp'


function DeliveryOffer({img}) {

  return (
  <section className='DeliveryOffer'>
    <div className='container'>
        <TitleSection title="Доставляємо по Києву" />
        <div className='DeliveryWrapper'>
            <div className='DeliveryImg'>
                <img src={img || DeliveryImg1} alt=''/>
            </div>
            <div className='DeliveryContent'>
                <h5>Замовити сет надувних кульок до святкового настрою з нами!</h5>
                <p>Ви шукаєте незабутні моменти для вашого свята? Ми тут, щоб зробити ваше святкування неповторним! Наш інтернет-магазин пропонує широкий вибір надувних кульок, фотозон і гірлянд, які додають атмосферу веселощів та радості будь-якому заході.</p>
                <div>
                    <p>З нами ви отримаєте:</p>
                    <ul>
                        <li>Сети кульок на будь-який смак та заходи;</li>
                        <li>Швидку та зручну доставку прямо до вашого дверей;</li>
                        <li>Індивідуальний підхід до кожного клієнта та професійну підтримку;</li>
                    </ul>
                </div>
                <a href='#section-contact' className='button'>Залишити заявку</a>
            </div>
        </div>
    </div>
  </section>
  )
}

export default DeliveryOffer
// // BasicForm.js
// import './BasicForm.css';
// import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
// import { sendToTelegram } from '../TelegramSender';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';

// function BasicForm() {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [successOpen, setSuccessOpen] = useState(false);
//   const [errorOpen, setErrorOpen] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       await sendToTelegram(`Заявка на зворотній звязок: ${phoneNumber}`);
//       setSuccessOpen(true);
//     } catch (error) {
//       console.error('Ошибка при отправке номера телефона в Telegram:', error);
//       setErrorOpen(true);
//     }
//   };

//   const handleChange = (event) => {
//     setPhoneNumber(event.target.value);
//   };

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSuccessOpen(false);
//     setErrorOpen(false);
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit} className='BasicForm'>
//         <InputMask
//           mask="+38 (099) 999-99-99"
//           value={phoneNumber}
//           onChange={handleChange}
//           placeholder="Введіть номер телефону"
//           required
//         >
//           {(inputProps) => <input {...inputProps} type="tel" />}
//         </InputMask>
//         <button type="submit">Надіслати</button>
//       </form>
//       <Snackbar className='' open={successOpen} autoHideDuration={4000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
//           Дякую, ваша заявка прийнята, ми вам зателефонуємо!
//         </Alert>
//       </Snackbar>
//       <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
//           Помилка при відправці заявки
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// }

// export default BasicForm;


// BasicForm.js
import './BasicForm.css';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { sendToTelegram } from '../TelegramSender';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function BasicForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [validationErrorOpen, setValidationErrorOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (phoneNumber.length !== 19) {
      setValidationErrorOpen(true);
      return;
    }
    try {
      await sendToTelegram(`🟢 Заявка на зворотній зв'язок: ${phoneNumber}`);
      // setSuccessOpen(true);
      window.location.href = '/thanks';

    } catch (error) {
      console.error('Ошибка при отправке номера телефона в Telegram:', error);
      setErrorOpen(true);
    }
  };

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
    setValidationErrorOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className='BasicForm'>
        <InputMask
          mask="+38 (099) 999-99-99"
          value={phoneNumber}
          onChange={handleChange}
          placeholder="Введіть номер телефону"
          required
        >
          {(inputProps) => <input {...inputProps} type="tel" />}
        </InputMask>
        <button type="submit">Надіслати</button>
      </form>
      <Snackbar open={successOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          Дякую, ваша заявка прийнята, ми вам зателефонуємо!
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          Помилка при відправці заявки
        </Alert>
      </Snackbar>
      <Snackbar open={validationErrorOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" variant="filled" sx={{ width: '100%' }}>
          Будь ласка, введіть повний номер телефону
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BasicForm;
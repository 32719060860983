import "./MobManu.css";
// librery
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// Assets
import ManuHeader from "../../../Assets/Icons/manuHeader.svg";
import logoText from "../../../Assets/Icons/logoText.svg";
import logoIcon from "../../../Assets/Icons/logoIcon.svg";

function MobManu() {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const handleLinkClick = () => {
    handleClose();
  };
  return (
    <>
      <Button variant="primary" onClick={toggleShow} className=" MobManuBtn">
        <img src={ManuHeader} alt="" />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
        placement="top"
        className="OffcanvasMobManu"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-[36px] text-center w-full pl-[32px]">
            <a href="#" className="lg:hidden">
              <img src={logoText} alt="" />
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className=" flex flex-col gap-[30px] items-center text-center mt-3">
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Link to="/">Головна</Link>
            </li>
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/shop" ? "active" : ""
              }`}
            >
              <Link to="/shop">Магазин</Link>
            </li>
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/search" ? "active" : ""
              }`}
            >
              <Link to="/search">Каталог</Link>
            </li>
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              <Link to="/about">Про нас</Link>
            </li>
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/delivery" ? "active" : ""
              }`}
            >
              <Link to="/delivery">Доставка</Link>
            </li>
            <li onClick={handleLinkClick}
              className={`header__text text__hover ${
                location.pathname === "/contacts" ? "active" : ""
              }`}
            >
              <Link to="/contacts">Контакти</Link>
            </li>
          </ul>
          <div className="flex justify-center mt-5">
            <img src={logoIcon} alt="" />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobManu;

import HeroSlider from './HeroSlider/HeroSlider'
import './HomeHero.css'
import HomeHero1 from "../../Assets/Img/Home/Hero/1.webp"
import HomeHero2 from "../../Assets/Img/Home/Hero/2.webp"
import HomeHero3 from "../../Assets/Img/Home/Hero/3.webp"
import HomeHero4 from "../../Assets/Img/Home/Hero/4.webp"
import HomeHero5 from "../../Assets/Img/Home/Hero/5.webp"


function HomeHero() {

  return (
  <>
    <section className='HomeHero'>
        <div className='container'>
            <div className='flex flex-col gap-[20px] '>
                <div className=' grid grid-cols-3 md:grid-cols-12 gap-[20px]'>
                    <HeroSlider />
                    <div className=' heroWrapperTop '>
                        <div  className='heroCard'>
                            <img src={HomeHero1} alt='' />
                            <div className='heroCard-content'>
                                <h4>Кульки від найкращих світових брендів: </h4>
                                <p><h4>Anagram (США), Qualatex (CША), Grabo (Італія), Flexmetal (Іспанія)</h4></p>
                            </div>
                        </div>
                        <div  className='heroCard'>
                            <img src={HomeHero2} alt='' />
                            <div className='heroCard-content'>
                                <h4>Наші декоратори подбають про ваше свято, зроблять його незабутньо прекрасним</h4>
                                {/* <p>Великий вибір Фотозон в Києві</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' grid grid-cols-4 md:grid-cols-12 gap-[20px] '>
                    <div  className='heroCard heroBottom justify-end heroWhite'>
                        <img src={HomeHero3} alt='' />
                        <div className='heroCard-content text-end'>
                            <h4>Наші кульки літають від 3 до 15 днів</h4>
                            {/* <p>Дуже широкий вибір латексних кульок</p> */}
                        </div>
                    </div>
                    <div  className='heroCard heroBottom justify-end heroWhite'>
                        <img src={HomeHero4} alt='' />
                        <div className='heroCard-content'>
                            <h4>Тематичні гелеві кульки на будь яке свято - розкішні та бюджетні варіанти</h4>
                            {/* <p>Надувні кульки для незабутнього дівич-вечора!</p> */}
                        </div>
                    </div>
                    <div  className='heroCard heroBottom'>
                        <img src={HomeHero5} alt='' />
                        <div className='heroCard-content'>
                            <h4>Знижки до 20% в святкові дні</h4>
                            {/* <p>Знижки до 50% на замовлення квітів</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
  )
}

export default HomeHero


import React, { useState, useEffect } from "react";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CheckoutBreadcrmbs from "../../Components/Shops/Breadcrumbs/CheckoutBreadcrmbs";
import { useBasket } from "../../context/BasketContext";
import EmptyBasket from "../../Components/Shops/Basket/EmptyBasket/EmptyBasket";
import Trash from "../../Assets/Icons/trash.svg";
import Minus from "../../Assets/Icons/minus.svg";
import Plus from "../../Assets/Icons/plus.svg";
import { Link, useNavigate } from "react-router-dom"; // Changed useHistory to useNavigate
import { transliterate } from "transliteration";
import CardBallon from "../../Components/Shops/Shop/ShopCard/CardBallon/CardBallon";
import "./Checkout.css";
import defoltFoto from "../../Assets/Img/Shop/defoltFoto.svg"
import InputMask from 'react-input-mask';
import { sendToTelegram } from '../../Components/Forms/TelegramSender';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function Checkout() {
  const [show, setShow] = useState(true);
  const { basket, updateQuantity, removeFromBasket, clearBasket } = useBasket();
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    delivery: "",
    payment: "",
    comment: "",
  });
  const [errorOpen, setErrorOpen] = useState(false);
  const navigate = useNavigate(); // Replaced useHistory with useNavigate

  useEffect(() => {
    const total = basket.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    setTotalPrice(total);
  }, [basket]);

  const handleDecreaseQuantity = (index) => {
    if (basket[index].quantity > 1) {
      updateQuantity(index, basket[index].quantity - 1);
    }
  };

  const handleIncreaseQuantity = (index) => {
    updateQuantity(index, basket[index].quantity + 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, delivery, payment } = formData;

    if (!name || !phone || !delivery || !payment) {
      setErrorOpen(true);
      return;
    }

    const requestPayload = {
      name,
      phone,
      delivery_type: delivery === "pickup" ? 1 : 2,
      payment_type: payment === "bank_transfer" ? 1 : 2,
      comment: formData.comment || "no comment",
      orders: basket.map((item) => ({
        product_id: item.id,
        color: item.selectedColor.color.toString(),
        count: item.quantity,
      })),
    };

    console.log("Sending data:", requestPayload);

    try {
      const response = await fetch(`${apiUrl}/api/requests`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        body: JSON.stringify(requestPayload),
        mode: 'cors'
      });

      if (response.ok) {
        // Формируем сообщение для отправки в Telegram
         const message = `
         🟢 Вітаю, надійшло Нове замовлення:
         Ім'я: ${name}
         Телефон: ${phone}
         Доставка: ${delivery}
         Оплата: ${payment}
         Коментар: ${formData.comment || "без коментарів"}
         Товари:
         ${basket.map(item => `${item.title} (колір: ${item.selectedColor.color}) - ${item.quantity} шт. - ${item.price * item.quantity} грн`).join('\n')}
         Загальна сума: ${totalPrice} грн
       `;

        try {
          await sendToTelegram(message);
          console.log("Сообщение отправлено в Telegram");
        } catch (error) {
          console.error("Ошибка при отправке сообщения в Telegram:", error);
        }

        clearBasket(); // Очистка корзины после успешной отправки данных
        window.location = "/thanks"; // Changed history.push to navigate('/thanks')
      } else {
        console.error("Failed to submit order");
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  const handleClose = () => {
    setErrorOpen(false);
  };

  useEffect(() => {
    basket.forEach((item) => console.log(item.selectedColor));
  }, [basket]);
  
  return (
    <section className="Checkout">
      <div className="container">
        <div className="flex flex-col gap-[20px] ">
          <CheckoutBreadcrmbs />
          <form
            className="flex flex-col gap-[20px] sm:grid sm:grid-cols-12 "
            onSubmit={handleSubmit}
          >
            <div className="col-span-7">
              <div className="sticky top-[90px] bg-a-buzok-0 rounded-[20px] px-[16px] py-[20px] sm:p-[80px] flex flex-col gap-[40px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="Checkout__title">
                    Контактні дані<span className="impot">*</span>
                  </p>
                  <div className="flex flex-col gap-[20px]">
                    <FormControl>
                      <FormLabel>
                        Прізвище, ім’я<span className="impot">*</span>
                      </FormLabel>
                      <TextField
                        placeholder="Введіть ім’я"
                        variant="outlined"
                        margin="none"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        Телефон<span className="impot">*</span>
                      </FormLabel>
                      <TextField
                        mask="+38 (099) 999-99-99"
                        placeholder="Введіть телефон"
                        variant="outlined"
                        margin="none"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col gap-[20px]">
                  <p className="Checkout__title">
                    Доставка<span className="impot">*</span>
                  </p>
                  <div className="flex flex-col gap-[20px]">
                    <FormControl>
                      <RadioGroup
                        name="delivery"
                        value={formData.delivery}
                        onChange={handleInputChange}
                      >
                        <FormControlLabel
                          value="pickup"
                          control={<Radio />}
                          label={
                            <Typography>
                              Самовивіз -{" "}
                              <a href="#">(вул. Сім’ї Стешенків, 1)</a>
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="takeaway"
                          control={<Radio />}
                          label={
                            <Typography>
                              Доставка - (за тарифами перевізника)
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col gap-[20px]">
                  <p className="Checkout__title">
                    Оплата<span className="impot">*</span>
                  </p>
                  <div className="flex flex-col gap-[20px]">
                    <FormControl>
                      <RadioGroup
                        name="payment"
                        value={formData.payment}
                        onChange={handleInputChange}
                      >
                        <FormControlLabel
                          value="bank_transfer"
                          control={<Radio />}
                          label={<Typography>Оплата за реквізитами</Typography>}
                        />
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label={
                            <Typography>Готівка</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col gap-[20px]">
                  <p className="Checkout__title">Коментар</p>
                  <div className="flex flex-col gap-[20px]">
                    <FormControl>
                      <FormLabel>Введіть свої побажання</FormLabel>
                      <TextField
                        multiline
                        placeholder="Введіть коментар"
                        variant="outlined"
                        margin="none"
                        name="comment"
                        value={formData.comment}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-5 relative">
              <div className=" sticky top-[90px]  border-[1px] border-a-active-0 rounded-[20px] bg-white ">
                <p className="text-[36px] text-center w-full pl-[32px] relative bg__basket-title py-[8px] leading-[120%]">
                  Кошик
                </p>
                {basket.length === 0 ? (
                  <div className="p-4">
                    <EmptyBasket />

                  </div>
                ) : (
                  <>
                    <div className="px-[16px] sm:px-[20px] ">
                      <div>
                        {basket.map((item, index) => (
                          <div key={index} className="BasketItem">
                            {/* <img
                              className="BasketItem__img"
                              src={item.selectedColor.photoColor}
                              alt=""
                            /> */}
                            <img
                              className="BasketItem__img"
                              src={
                                item.selectedColor.photo
                                  ? `https://backend.balloonflower.kyiv.ua/uploads/${item.selectedColor.photo}`
                                  : defoltFoto
                              }
                              alt={item.title}
                            />
                            <div className="BasketItem__content">
                              <div className="BasketItem__content-top">
                                <Link
                                  to={`/catalog/${encodeURIComponent(
                                    transliterate(item.title)
                                  )}`}
                                  className="BasketItem__title"
                                >
                                  {item.title}
                                </Link>
                                <img
                                  className="BasketItem__trash"
                                  src={Trash}
                                  alt=""
                                  onClick={() => removeFromBasket(index)}
                                />
                              </div>
                              <div className="ColorPanel">
                                <div className="relative flex items-center">
                                  <CardBallon
                                    color={item.selectedColor.color}
                                  />
                                </div>
                                <div className="ColorSwith">
                                  <div
                                    className="ColorBtn dark"
                                    onClick={() =>
                                      handleDecreaseQuantity(index)
                                    }
                                  >
                                    <img src={Minus} alt="" />
                                  </div>
                                  <span className="w-[28px] text-center">
                                    {item.quantity}
                                  </span>
                                  <div
                                    className="ColorBtn"
                                    onClick={() =>
                                      handleIncreaseQuantity(index)
                                    }
                                  >
                                    <img src={Plus} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="ProductDetail__bottom">
                                <div className="ProductDetail__price">
                                  <p className="actual__price">
                                    {item.price} грн/шт.
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between">
                                <p className="ProductDetail__card-price">
                                  Ціна:
                                </p>
                                <p className="ProductDetail__card-price">
                                  <span>{item.price * item.quantity}</span> грн
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="BasketAll">
                        <div className="BasketAll__priseAll">
                          <p className="BasketAll__priseAll-text">
                            До сплати без доставки:
                          </p>
                          <p className="BasketAll__priseAll-price">
                            <span>{totalPrice}</span> грн
                          </p>
                        </div>
                        <button type="submit" className="button">
                          Оформити замовлення
                        </button>
                        <p className="Checkout__policy block">
                          Натискаючи кнопку “Оформити замовлення”, ви
                          погоджуєтеся з обробкою пресональних данних та{" "}
                          <a href="/privat-policy">
                            Політикою конфіденційності
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Будь ласка, заповніть дані доставки
        </Alert>
      </Snackbar>
    </section>
  );
}

export default Checkout;

import './Delivery.css'


function Delivery() {

  return (
  <>
    <section className='Delivery bg-delivery-bg lg:min-h-[2100px]'>
      <div className='container'>
        <div className='containerWrapper'>
          <div className='content bg-[#ffffff] bg-opacity-70 sm:bg-opacity-0 rounded-[20px] border-[1px] sm:border-none border-a-active-0  p-[20px] sm:p-[0px] '>
            <h1>Нижче наведена інформація про наші умови доставки:</h1>
            <div className='flex flex-col gap-[28px]'>
              <div className=' flex flex-col gap-[16px]'>
                <h6>Безкоштовна доставка при замовлені від 2000 грн. по Києву.*</h6>
                <ul>
                  <li>Здійснюється у трьох широких часових інтервалах:<br/>
                    1. В інтервалі з 9.00 до 15.00<br/>
                    2. В інтервалі з 11.00 до 17.00<br/>
                    3. В інтервалі з 13.00 до 19.00
                  </li>
                  <li>Кур'єр зателефонує Вам за 30 хвилин до приїзду</li>
                </ul>
              </div>
              <div className=' flex flex-col gap-[16px]'>
                <h6>Способи доставки:</h6>
                <ul>
                  <li>Кур'єрська доставка: Ми пропонуємо кур'єрську доставку в межах міста Києва. Доставка здійснюється протягом [вкажіть термін доставки].</li>
                  <li>Самовивіз: Ви також можете забрати ваше замовлення самостійно з нашого пункту самовивозу за адресою [вкажіть адресу].</li>
                </ul>
              </div>
              <div>
                <h6>Вартість доставки:</h6>
                <ul>
                  <li>Вартість доставки обчислюється автоматично при оформленні замовлення та залежить від обраного способу доставки та обсягу замовлення.</li>
                </ul>
              </div>
              <div>
                <h6>Терміни доставки:</h6>
                <ul>
                  <li>Ми намагаємося здійснити доставку в найкоротші терміни після підтвердження замовлення. Терміни доставки можуть варіюватися в залежності від часу оформлення замовлення та наявності товару.</li>
                </ul>
              </div>
              <div>
                <h6>Стеження за замовленням:</h6>
                <ul>
                  <li>Після оформлення замовлення ви отримаєте підтвердження з деталями вашого замовлення. Ви можете відстежувати статус вашого замовлення у менеджера.</li>
                </ul>
              </div>
              <p>Якщо у вас є будь-які питання щодо умов доставки, будь ласка, зв'яжіться з нашим менеджером.</p>
            </div>

            <div>
              <a href='/contacts' className='button buzok'>Зв'язатися</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default Delivery